import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { format } from "date-fns";

import { ContentBox, FormSection, ActionButton } from "@gelsenwasser/react";

import { GetTerminSteps } from "App";
import ProcessLine from "components/ProcessLine";
import { BsArrowLeft } from "react-icons/bs";
import { useGetTerminDaten, useGetBenachrichtigungDaten, useGetSelectedTerminSlot } from "./SessionHandling";
import { contentBoxStyles, myBreakPoints } from "components/Layout";
import { NeuerTermin } from "./TerminsucheTypes";
import { useCreateTermin } from "./Mutations";
import Loader from "components/Loader";

const Absenden: React.FC = () => {
  // hooks
  const history = useHistory();
  const steps = GetTerminSteps();
  const termindaten = useGetTerminDaten();
  const selectedTerminSlot = useGetSelectedTerminSlot();
  const benachrichtigung = useGetBenachrichtigungDaten();
  const { mutate: createTermin, isError, isLoading } = useCreateTermin();

  // states
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    document.title = `TerminPortal - ${steps[3].title}`;
  }, []);

  // daten
  const vonDatum = selectedTerminSlot?.von ?? termindaten.kundenDaten.termin.dateFrom;
  const bisDatum = selectedTerminSlot?.bis ?? termindaten.kundenDaten.termin.dateTo;

  // handler
  const onSubmit = () => {
    const neuerTermin: NeuerTermin = {
      bestaetigung: benachrichtigung?.wuenschtBestaetigung ?? false,
      erinnerung: benachrichtigung?.wuenschtErinnerung ?? false,
      alterTerminVon: termindaten.kundenDaten.termin.dateFrom,
      neuerTerminVon: vonDatum,
      neuerTerminBis: bisDatum,
      nachricht: benachrichtigung?.bemerkung ?? "",
      email: benachrichtigung?.kundeEmail ?? "",
      phone: benachrichtigung?.kundeTelefon ?? "",
    };

    createTermin(neuerTermin, {
      onSuccess: () => {
        history.push("bestaetigung");
      },
    });
  };
  const goBack = () => {
    history.push("benachrichtigung");
  };

  return (
    <>
      <ProcessLine steps={steps} currentStep={"Absenden"} />
      <ContentBox title="Absenden" {...contentBoxStyles}>
        <Row>
          <Col>Sie senden Folgende Informationen an uns:</Col>
        </Row>
        <Row>
          <Col>
            Die <b>Zählerwechslung</b> findet statt am:
            <br />
            <b>
              {format(vonDatum, "dd.MM.yyyy")} zwischen {format(vonDatum, "HH:mm")} und {format(bisDatum, "HH:mm")} Uhr
            </b>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              checked={benachrichtigung?.wuenschtErinnerung}
              type="checkbox"
              label="Ja, bitte eine Terminbestätigung sowie Erinnerung per E-Mail"
              disabled
            />
          </Col>
        </Row>
        <Row className="mt-0">
          <Col>
            <Form.Check
              checked={benachrichtigung?.wuenschtBestaetigung}
              type="checkbox"
              label="Ja, bitte eine Wechselbestätigung per E-Mail (nach erfolgter Zählerwechslung)"
              disabled
            />
          </Col>
        </Row>

        {benachrichtigung?.bemerkung && (
          <Row>
            <Col {...myBreakPoints}>
              <label>
                Ihre Nachrict an uns:
                <br />
                <i>{benachrichtigung.bemerkung}</i>
              </label>
            </Col>
          </Row>
        )}
        {benachrichtigung?.wuenschtBestaetigung || benachrichtigung?.wuenschtErinnerung ? (
          <>
            <Row>
              <Col {...myBreakPoints}>Ihre Kontaktdaten:</Col>
            </Row>

            <Row>
              <Col {...myBreakPoints}>
                <b>E-Mail:</b>
              </Col>
              <Col>
                <b>{benachrichtigung.kundeEmail}</b>
              </Col>
            </Row>

            <Row className="mt-0">
              <Col {...myBreakPoints}>
                <b>Telefon:</b>
              </Col>
              <Col>
                <b>{benachrichtigung.kundeTelefon}</b>
              </Col>
            </Row>
          </>
        ) : null}

        <Row>
          <Col>
            <label>Bitte klicken Sie zum Abschluss auf absenden.</label>
          </Col>
        </Row>
        <FormSection title="">
          <Row>
            <Col>
              <ActionButton caption="zurück" type="submit" icon={BsArrowLeft} onClick={goBack} disabled={isLoading} />
              <ActionButton
                className="float-right"
                caption="absenden"
                type="submit"
                onClick={onSubmit}
                disabled={isError || isLoading}
              />
            </Col>
          </Row>
        </FormSection>
      </ContentBox>
      <Loader show={isLoading} caption="Daten werden gesendet ..." />
    </>
  );
};
export default Absenden;
