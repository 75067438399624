import { Footer, Header } from "@gelsenwasser/react";
import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";

interface Props {
  children: ReactNode;
}

export const myBreakPoints = {
  lg: 2,
  md: 4,
  cs: 12,
};

export const contentBoxStyles = { className: "pl-5 pr-5" };

export const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <Header title="" />

      <main className="d-flex flex-column flex-fill outermain bg-gw-light">
        <Container className="mt-5">{children}</Container>
      </main>

      <Footer />
    </>
  );
};
