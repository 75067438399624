import React from "react";
import classNames from "classnames/bind";

import styles from "./ProcessLine.module.scss";
import { MdCheck, MdFiberManualRecord, MdFlag } from "react-icons/md";
import { Link } from "react-router-dom";
import ConditionalWrapper from "common/ConditionalWrapper";

const cx = classNames.bind(styles);

export interface ProcessLineStep {
  key: string;
  title: string;
  url: string;
}
export interface ProcessLineProps {
  steps: Array<ProcessLineStep>;
  currentStep: string;
  isInvalid?: boolean;
  isCompleted?: boolean;
}

const ProcessLine: React.FC<ProcessLineProps> = ({
  steps,
  currentStep,
  isInvalid = false,
  isCompleted = false,
}: ProcessLineProps) => {
  const activeIdx = isCompleted ? steps.length : steps.findIndex((item) => item.key === currentStep);

  return (
    <div className={styles.Steps__Wrapper + " d-print-none"}>
      <ol className={styles.Steps}>
        {steps.map((item, index) => {
          const isLastStep = index === steps.length - 1;
          const completed = index < activeIdx;
          const stepWidth = 100 / steps.length;
          const isCurrent = index === activeIdx;

          const stepClasses = cx({
            Step: true,
            Step__Last: isLastStep,
            Step__Current: isCurrent && !isInvalid,
            Step__Invalid: isCurrent && isInvalid,
            Step__Completed: completed,
          });

          return (
            <li style={{ left: `${stepWidth / 2}%` }} key={item.key} className={stepClasses}>
              <ConditionalWrapper
                condition={completed || isCurrent}
                wrapper={(children) => (
                  <Link to={item.url}>
                    {children}
                  </Link>
                )}
              >
                <span className={styles.Step__Milestone}>
                  {completed && !isLastStep && <MdCheck />}
                  {isCurrent && <MdFiberManualRecord />}
                  {isLastStep && isCompleted && <MdFlag />}
                </span>
                <span className={styles.Step__Label}>{item.title}</span>
              </ConditionalWrapper>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default ProcessLine;
