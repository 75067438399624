import { useContext } from "react";
import { AxiosError } from "axios";

import { UseQueryResult, useQuery, UseQueryOptions } from "react-query";

import { useNotification, NotificationType } from "../services/Notification";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { ApiContext } from "services/ApiContext";
import { AuftragsLogin, TerminSlotData } from "./TerminsucheTypes";

export const useGetTermine = (
  auftragsLogin: AuftragsLogin
): UseQueryResult<Record<string, TerminSlotData[]>, AxiosError> => {
  const { addNotification } = useNotification();
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const GetTermine = async ({ queryKey }): Promise<Record<string, TerminSlotData[]>> => {
    const [, auftragsLogin]: [unknown, AuftragsLogin] = queryKey;

    log.debug(
      `getting available Termine for Auftragsnummer '${auftragsLogin.auftragsnummer}' and Zaehlernummer '${auftragsLogin.zaehlernummer}'`
    );

    const response = await api.get("api/Termin");
    log.trace(
      { obj: response.data },
      `got TerminSlot response for Termine for Auftragsnummer '${auftragsLogin.auftragsnummer}' and Zaehlernummer '${auftragsLogin.zaehlernummer}'`
    );

    return response.data;
  };

  // query nur aktivieren, wenn es eine Auftragsnummer und Zaehlernummer gibt
  const queryObj: UseQueryOptions<Record<string, TerminSlotData[]>, AxiosError> = {
    queryKey: ["GetTermine", auftragsLogin],
    enabled: !!auftragsLogin.auftragsnummer && !!auftragsLogin.zaehlernummer,
    queryFn: GetTermine,
    onError: (err) => {
      if (err.response?.status === 401)
        addNotification(
          "Ihre Anmeldung ist ungültig oder abgelaufen, bitte melden Sie sich neu an.",
          "Anmeldedaten ungültig",
          NotificationType.Alert
        );
      else logAxiosError("Error getting Termine", "Fehler beim Abholen der verfügbaren Termine", err);
    },
  };

  return useQuery<Record<string, TerminSlotData[]>, AxiosError>(queryObj);
};
