import { useHistory } from "react-router-dom";

import { useNotification, NotificationType } from "../services/Notification";
import { handleDates } from "services/ApiContext";
import { AuthData, KundeKontaktDaten, SelectedSlot } from "./TerminsucheTypes";

export const useGetTerminDaten = (): AuthData => {
  const { addNotification } = useNotification();
  const history = useHistory();
  const dataJson = sessionStorage.Termindaten;
  if (!dataJson) {
    addNotification(
      "Es wurden keine gültigen Auftragsdaten gefunden",
      "Auftragsdaten nicht vorhanden",
      NotificationType.Alert
    );
    history.push("/");
    return {
      auftragsnummer: "",
      token: "",
      tokenExpirationTime: 0,
      zaehlernummer: "",
      kundenDaten: {
        anrede: "",
        enthaeltBemerkung: false,
        kundeAdresse: "",
        kundeKontaktDaten: {
          bemerkung: "",
          kundeEmail: "",
          kundeTelefon: "",
          wuenschtBestaetigung: false,
          wuenschtErinnerung: false,
        },
        sparte: "",
        termin: {
          dateFrom: new Date(),
          dateTo: new Date(),
        },
        terminKurzBevorstehend: false,
      },
    };
  }
  const data: AuthData = JSON.parse(dataJson);
  handleDates(data);
  return data;
};

export const useGetBenachrichtigungDaten = (): KundeKontaktDaten | null => {
  const benachrichtigungJson = sessionStorage.getItem("Benachrichtigung");
  const benachrichtigung: KundeKontaktDaten = benachrichtigungJson ? JSON.parse(benachrichtigungJson) : null;
  return benachrichtigung;
};

export const useGetSelectedTerminSlot = (): SelectedSlot | null => {
  const selectedTerminSlotJson = sessionStorage.getItem("SelectedTerminSlot");
  const selectedTerminSlot: SelectedSlot = selectedTerminSlotJson ? JSON.parse(selectedTerminSlotJson) : null;
  handleDates(selectedTerminSlot);
  return selectedTerminSlot;
};
