import { FormikCheck, FormikControl } from "3rdParty/formik-react-bootstrap";
import { GetTerminSteps } from "App";
import ProcessLine from "components/ProcessLine";
import { ContentBox, FormSection, ActionButton } from "@gelsenwasser/react";

import { Formik, Form as FormikForm } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, OverlayTrigger, Popover, Row, Form } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { KundeKontaktDaten } from "./TerminsucheTypes";
import { useGetBenachrichtigungDaten, useGetTerminDaten } from "./SessionHandling";
import { contentBoxStyles } from "components/Layout";

const maxNachrichtLength = 200;

const BenachrichtigungSchema = yup.object({
  kundeEmail: yup
    .string()
    .email("Keine gültige E-Mail Adresse")
    .when(["wuenschtErinnerung", "wuenschtBestaetigung"], {
      is: (wuenschtErinnerung, wuenschtBestaetigung) => wuenschtErinnerung || wuenschtBestaetigung,
      then: yup.string().required("Bitte eine E-Mail Adresse eingeben"),
    }),
  wuenschtErinnerung: yup.boolean(),
  wuenschtBestaetigung: yup.boolean(),
  kundeTelefon: yup.string(),
  bemerkung: yup.string().max(maxNachrichtLength, `Bitte nur maximal ${maxNachrichtLength} Zeichen eingeben`),
});

const Benachrichtigung: React.FC = () => {
  // hooks
  const history = useHistory();
  const steps = GetTerminSteps();
  const termindaten = useGetTerminDaten();
  const benachrichtigung = useGetBenachrichtigungDaten() ?? {
    wuenschtBestaetigung: termindaten.kundenDaten.kundeKontaktDaten.wuenschtBestaetigung,
    wuenschtErinnerung: termindaten.kundenDaten.kundeKontaktDaten.wuenschtErinnerung,
    bemerkung: "",
    kundeEmail: "",
    kundeTelefon: "",
  };

  // states
  const [terminbestaetigung, setTerminbestaetigung] = useState(benachrichtigung.wuenschtErinnerung);
  const [wechselbestaetigung, setWechselbestaetigung] = useState(benachrichtigung.wuenschtBestaetigung);

  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    document.title = `TerminPortal - ${steps[2].title}`;
  }, []);

  // daten
  // handler
  const moveNext = (values: KundeKontaktDaten) => {
    sessionStorage.setItem("Benachrichtigung", JSON.stringify(values));
    history.push("/absenden");
  };
  const goBack = () => {
    history.push("/terminauswahl");
  };

  return (
    <>
      <ProcessLine steps={steps} currentStep={"Benachrichtigungen"} />
      <Formik initialValues={benachrichtigung} validationSchema={BenachrichtigungSchema} onSubmit={moveNext}>
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <FormikForm onSubmit={handleSubmit}>
              <ContentBox title="Benachrichtigungen" {...contentBoxStyles}>
                <Row>
                  <Col>Sie möchten erinnert und/oder informiert werden?</Col>
                </Row>
                <Row>
                  <Col>
                    <FormikCheck
                      onChange={() => {
                        setTerminbestaetigung(!terminbestaetigung);
                        setFieldValue("wuenschtErinnerung", !terminbestaetigung);
                      }}
                      checked={terminbestaetigung}
                      name="wuenschtErinnerung"
                      type="checkbox"
                      label="Ja, bitte eine Terminbestätigung sowie Erinnerung per E-Mail"
                      disabled={termindaten.kundenDaten.terminKurzBevorstehend}
                    />
                    <FormikCheck
                      onChange={() => {
                        setWechselbestaetigung(!wechselbestaetigung);
                        setFieldValue("wuenschtBestaetigung", !wechselbestaetigung);
                      }}
                      checked={wechselbestaetigung}
                      name="wuenschtBestaetigung"
                      type="checkbox"
                      label="Ja, bitte eine Wechselbestätigung per E-Mail (nach erfolgter Zählerwechslung)"
                      disabled={termindaten.kundenDaten.terminKurzBevorstehend}
                    />
                    {termindaten.kundenDaten.terminKurzBevorstehend && (
                      <p>
                        Es besteht keine Möglichkeit mehr die Art der Benachrichtigung zu ändern. Sie können uns jedoch
                        weiterhin eine Nachricht zukommen lassen.
                      </p>
                    )}
                  </Col>
                  <Col>
                    <b>Hinweis:</b>
                    <p>
                      Ihre Kontaktdaten werden (abhängig von Ihrer Auswahl) für eine Bestätigungs-E-Mail und etwaige
                      notwendige telefonische Rücksprachen bezüglich ihres Wechseltermins und nur im Zusammenhang mit
                      diesem Auftrag mit den hier verwendet. Eine Terminerinnerung per E-Mail erhalten Sie einen Tag vor
                      dem tatsächlichen Termin. Eine Wechselbestätigung erhalten Sie im Anschluss an den Wechsel.
                    </p>
                  </Col>
                </Row>
                {(terminbestaetigung || wechselbestaetigung) && (
                  <Row>
                    <Col md={6}>
                      <FormikControl name="kundeEmail" label="E-Mail *" placeholder="E-Mail-Adresse" />
                      <Row>
                        <Col sm={10}>
                          <FormikControl name="kundeTelefon" label="Telefon" placeholder="Telefonnummer" />
                        </Col>
                        <Col sm={2} className="align-bottom">
                          <OverlayTrigger
                            key="right"
                            trigger="click"
                            placement="auto"
                            overlay={
                              <Popover id={"1"}>
                                <div className="alert-primary p-2">
                                  Hinweise zum Datenschutz
                                  <br />
                                  <br />
                                  Die von Ihnen angegebenen Kontaktdaten werden nur im Zusammenhang mit diesem Auftrag
                                  erhoben und verarbeitet. Im Anschluss werden die Daten unverzüglich gelöscht.
                                  <br />
                                  <br />
                                  Weitere Informationen zum Datenschutz finden sie <a>hier</a>.
                                </div>
                              </Popover>
                            }
                          >
                            <Button className="w-100" style={{ marginTop: "32px!important" }} variant="info">
                              i
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="bemerkung">
                      <FormikControl
                        noFormGroup
                        maxLength={maxNachrichtLength}
                        as="textarea"
                        style={{ height: "7rem" }}
                        name="bemerkung"
                        label="Nachricht"
                        placeholder="Ihre Informationen an uns (z.B.: Hinweise für unseren Außendienst)"
                      />
                      <Form.Text className="text-secondary float-right">
                        {values.bemerkung.length} von {maxNachrichtLength}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <FormSection title="">
                  <Row>
                    <Col>
                      <ActionButton caption="zurück" type="submit" icon={BsArrowLeft} onClick={goBack} />
                      <ActionButton className="float-right" caption="weiter" type="submit" />
                    </Col>
                  </Row>
                </FormSection>
              </ContentBox>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
};
export default Benachrichtigung;
