import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { format } from "date-fns";
import germanLocale from "date-fns/locale/de";

import { ContentBox, FormSection, ActionButton } from "@gelsenwasser/react";
import { GetTerminSteps } from "App";
import ProcessLine from "components/ProcessLine";
import { useGetTerminDaten } from "./SessionHandling";
import { contentBoxStyles, myBreakPoints } from "components/Layout";

const Daten: React.FC = () => {
  // hooks
  const history = useHistory();
  const termindaten = useGetTerminDaten();
  const steps = GetTerminSteps();

  // states
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    document.title = `TerminPortal - ${steps[0].title}`;
  }, []);

  // daten
  const zaehlerTyp = termindaten.kundenDaten.sparte ? `${termindaten.kundenDaten.sparte}zähler` : "Zähler";

  // handler
  // handler
  const moveNext = () => {
    history.push("/TerminAuswahl");
  };

  return (
    <>
      <ProcessLine steps={steps} currentStep={"Daten"} />
      <ContentBox title="Daten" {...contentBoxStyles}>
        <Row>
          <Col>
            <p>Herzlich Willkommen im Terminportal!</p>
            <p>Wir möchten bei Ihnen den {zaehlerTyp} wechseln.</p>
          </Col>
        </Row>

        <Row>
          <Col {...myBreakPoints}>Am:</Col>
          <Col>
            <b>
              {format(termindaten.kundenDaten.termin.dateFrom, "EEEE, dd.MM.yyyy", {
                locale: germanLocale,
              })}
            </b>
          </Col>
        </Row>
        <Row className="mt-0">
          <Col {...myBreakPoints}>Zeit:</Col>
          <Col>
            <b>
              {format(termindaten.kundenDaten.termin.dateFrom, "HH:mm")} -{" "}
              {format(termindaten.kundenDaten.termin.dateTo, "HH:mm")}
            </b>
          </Col>
        </Row>

        <Row>
          <Col {...myBreakPoints}>
            <b>Auftragsnummer:</b>
          </Col>
          <Col>{termindaten.auftragsnummer}</Col>
        </Row>

        <Row className="mt-0">
          <Col {...myBreakPoints}>
            <b>Zählernummer:</b>
          </Col>
          <Col>{termindaten.zaehlernummer}</Col>
        </Row>
        <Row>
          <Col {...myBreakPoints}>
            <b>Verbrauchsstelle:</b>
          </Col>
          <Col>{termindaten.kundenDaten.kundeAdresse}</Col>
        </Row>

        <Row>
          <Col>Auf den folgenden Seiten können Sie Ihren Termin bearbeiten.</Col>
        </Row>
        <FormSection title="">
          <Row>
            <Col>
              <ActionButton className={"float-right"} caption="weiter" type="submit" onClick={moveNext} />
            </Col>
          </Row>
        </FormSection>
      </ContentBox>
    </>
  );
};
export default Daten;
