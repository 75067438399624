import { GetTerminSteps } from "App";
import ProcessLine from "components/ProcessLine";
import TerminInfoBox from "components/TerminInfoBox";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Calendar from "react-calendar";
import { format } from "date-fns";

import { useCallback } from "react";
import { useGetTermine } from "./Queries";
import { SelectedSlot, TerminSlotData } from "./TerminsucheTypes";
import { log } from "common/Logger";
import { useGetSelectedTerminSlot, useGetTerminDaten } from "./SessionHandling";
import { contentBoxStyles, myBreakPoints } from "components/Layout";
import ZeitSlotPicker from "components/ZeitSlotPicker";

import "styles/calendar.scss";
import Loader from "components/Loader";
import { ContentBox, FormSection, ActionButton } from "@gelsenwasser/react";

const TerminAuswahl: React.FC = () => {
  // hooks
  const history = useHistory();
  const steps = GetTerminSteps();
  const termindaten = useGetTerminDaten();
  const savedTerminSlot = useGetSelectedTerminSlot();

  // states
  const [selectedDate, setSelectedDate] = useState<Date>(
    savedTerminSlot?.von ?? termindaten.kundenDaten.termin.dateFrom
  );
  const [availableSlots, setAvailableSlots] = useState<Array<TerminSlotData>>([]);
  const [selectedSlot, setSelectedSlot] = useState<SelectedSlot | null>(savedTerminSlot);

  // parameter
  // queries und mutationen
  const terminSlotQuery = termindaten.kundenDaten.terminKurzBevorstehend
    ? null
    : useGetTermine({
        auftragsnummer: termindaten.auftragsnummer,
        zaehlernummer: termindaten.zaehlernummer,
      });

  // effekte
  useEffect(() => {
    if (selectedDate && terminSlotQuery?.data) {
      const foundSlots = terminSlotQuery.data[format(selectedDate, "dd/MM/yyyy")];
      log.debug({ obj: foundSlots }, "available slots for selected date: " + selectedDate);
      setAvailableSlots(foundSlots ?? []);
    }
  }, [selectedDate, terminSlotQuery?.data]);
  useEffect(() => {
    document.title = `TerminPortal - ${steps[1].title}`;
  }, []);

  // daten
  const selectedSlotTitle = availableSlots.find((x) => x.id === selectedSlot?.id)?.timeSlot;

  // handler
  const moveNext = () => {
    sessionStorage.setItem("SelectedTerminSlot", JSON.stringify(selectedSlot));
    history.push("/Benachrichtigung");
  };
  const goBack = () => {
    history.push("/Daten");
  };

  const onDateSelected = useCallback(
    (date) => {
      log.debug({ obj: date }, "a date was selected");
      setSelectedDate(date);
    },
    [terminSlotQuery?.data]
  );

  const onSlotSelected = (foundSlot: TerminSlotData) => {
    log.debug({ obj: foundSlot }, "a slot was selected");
    if (foundSlot) {
      log.debug("selected slot id: " + foundSlot.id);

      setSelectedSlot({
        id: foundSlot.id,
        von: foundSlot.from,
        bis: foundSlot.to,
      });
    }
  };

  const checkDate = (date: Date, terminSlots?: Record<string, TerminSlotData[]>): boolean => {
    if (terminSlots) {
      const formattedDate = format(date, "dd/MM/yyyy");
      return !terminSlots[formattedDate];
    }
    return false;
  };

  const tileDisabled = useCallback(
    ({ date }) => {
      return checkDate(date, terminSlotQuery?.data);
    },
    [terminSlotQuery?.data]
  );

  const datePicker = (
    <>
      <Row>
        <Col>
          Unten finden Sie eine Liste der verfügbaren Terminen und Zeitfenster.
          <br />
          Wählen Sie einen Termin aus und klicken Sie auf ein Zeitfenster, um mit der Terminänderung fortzufahren.
        </Col>
      </Row>
      <Row>
        <Col>
          <p>1. Wunschtermin wählen:</p>
          <Calendar onChange={onDateSelected} value={selectedDate} tileDisabled={tileDisabled} />
        </Col>
        <Col>
          <p>2. Zeitslot wählen:</p>
          <ZeitSlotPicker
            availableSlots={availableSlots}
            onSlotSelected={onSlotSelected}
            selectedSlotTitle={selectedSlotTitle}
          />
        </Col>
      </Row>
      <Row>
        <Col {...myBreakPoints}>
          <b>Verbrauchsstelle:</b>
        </Col>
        <Col>{termindaten.kundenDaten.kundeAdresse}</Col>
      </Row>
      {termindaten.branding?.telefon ? (
        <Row>
          <Col>
            <TerminInfoBox telefonNummer={termindaten.branding.telefon} />
          </Col>
        </Row>
      ) : null}
    </>
  );

  const noTerminChangePossible = (
    <>
      Ihr Termin vom {termindaten.kundenDaten.termin.dateFrom.toLocaleDateString()} zwischen{" "}
      {termindaten.kundenDaten.termin.dateFrom.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} Uhr und{" "}
      {termindaten.kundenDaten.termin.dateTo.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} Uhr kann
      nicht mehr geändert werden.
    </>
  );

  return (
    <>
      <ProcessLine steps={steps} currentStep={"Terminauswahl"} />
      <ContentBox title="Terminauswahl" {...contentBoxStyles}>
        {termindaten.kundenDaten.terminKurzBevorstehend ? noTerminChangePossible : datePicker}
        <FormSection title="">
          <Row>
            <Col>
              <ActionButton
                caption="zurück"
                type="submit"
                icon={BsArrowLeft}
                onClick={goBack}
                disabled={terminSlotQuery?.isFetching || terminSlotQuery?.isLoading}
              />
              <ActionButton
                className="float-right"
                caption="weiter"
                type="submit"
                onClick={moveNext}
                disabled={terminSlotQuery?.isFetching || terminSlotQuery?.isLoading || terminSlotQuery?.isError}
              />
            </Col>
          </Row>
        </FormSection>
      </ContentBox>
      <Loader
        show={terminSlotQuery?.isLoading || terminSlotQuery?.isFetching}
        caption="Termindaten werden geladen ..."
      />
    </>
  );
};
export default TerminAuswahl;
