import React from "react";
import { Row, Col, Button } from "react-bootstrap";

import { TerminSlotData } from "domain/TerminsucheTypes";

const Zeitslots = [
  "07:00 - 08:00",
  "08:00 - 09:00",
  "09:00 - 10:00",
  "10:00 - 11:00",
  "11:00 - 12:00",
  "12:00 - 13:00",
  "13:00 - 14:00",
  "14:00 - 15:00",
  "15:00 - 16:00",
];

interface ZeitSlotPickerProps {
  selectedSlotTitle?: string;
  onSlotSelected?: (itemSelected: TerminSlotData) => void;
  availableSlots?: Array<TerminSlotData>;
}

const ZeitSlotPicker: React.FC<ZeitSlotPickerProps> = ({
  selectedSlotTitle: selectedSlotId,
  availableSlots,
  onSlotSelected,
}: ZeitSlotPickerProps) => {
  const onItemSelected = (selectedItem?: TerminSlotData) => {
    if (onSlotSelected && selectedItem) onSlotSelected(selectedItem);
  };

  return (
    <Row>
      {Zeitslots.map((item) => {
        const isSlotAvailable = availableSlots?.find((x) => x.timeSlot === item);
        const isSlotSelected = item == selectedSlotId;
        const myVariant = isSlotAvailable ? (isSlotSelected ? "primary" : "outline-primary") : "secondary";

        return (
          <Col className="mb-4" sm={12} md={6} lg={4} key={item}>
            <Button variant={myVariant} disabled={!isSlotAvailable} onClick={() => onItemSelected(isSlotAvailable)}>
              {item}
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default ZeitSlotPicker;
