import React from "react";
import { Card } from "react-bootstrap";
import classNames from "classnames";

interface TerminInfoBoxProps extends React.HTMLAttributes<HTMLElement> {
  telefonNummer: string;
}

const TerminInfoBox: React.FC<TerminInfoBoxProps> = ({ className, telefonNummer, ...props }: TerminInfoBoxProps) => {
  return (
    <Card className={classNames(className, "alert-primary", "text-dark")} {...props}>
      <Card.Body>
        <p>
          Keinen Termin gefunden? Rufen Sie uns an: Telefon <b>{telefonNummer}</b>
        </p>
      </Card.Body>
    </Card>
  );
};

export default TerminInfoBox;
