import React, { ReactElement } from "react";
import { Alert, Toast } from "react-bootstrap";
import { useNotification } from "./useNotification";
import { NotificationType } from "./NotificationProvider";

export function Notification(): ReactElement | null {
  const { notification, removeNotification } = useNotification();

  const handleSubmit = () => {
    removeNotification();
  };

  if (notification)
    switch (notification.type) {
      case NotificationType.Alert:
        return (
          <div
            aria-live="polite"
            aria-atomic="true"
            className="position-absolute w-100 d-flex flex-column p-2 align-items-center"
            style={{ zIndex: 1500 }}
          >
            <Alert variant="danger" className="w-75" dismissible onClose={handleSubmit}>
              {notification && notification.status && <Alert.Heading>{notification.status}</Alert.Heading>}
              {notification && notification.message && <p>({notification.message})</p>}
            </Alert>
          </div>
        );

      case NotificationType.Toast:
        return (
          <div
            aria-live="polite"
            aria-atomic="true"
            className="position-absolute w-100 d-flex flex-row p-2 justify-content-end"
            style={{ zIndex: 1400 }}
          >
            {/* delay={3000} autohide */}
            <Toast onClose={handleSubmit} className="ml-auto" >
              {notification && notification.status && (
                <Toast.Header>
                  <strong className="mr-auto">{notification.status}</strong>
                  &nbsp;
                  <small>just now</small>
                </Toast.Header>
              )}
              {notification && notification.message && <Toast.Body>{notification.message}</Toast.Body>}
            </Toast>
          </div>
        );
    }

  return null;
}
