import React, { useState, useCallback, ReactNode, ReactElement } from "react";

export interface NotificationObject {
  message: string;
  status: string;
  type: NotificationType;
}

export enum NotificationType {
  Alert,
  Toast,
}

export interface NotificationContextProps {
  notification: NotificationObject | null;
  addNotification(message: string, status: string, type?: NotificationType): void;
  removeNotification(): void;
}

export interface NotificationProviderProps {
  children: ReactNode;
}

export const NotifcationContext = React.createContext<NotificationContextProps>({
  notification: null,
  addNotification: () => void 0,
  removeNotification: () => void 0,
});

export function NotificationProvider(props: NotificationProviderProps): ReactElement {
  const [notification, setNotification] = useState<NotificationObject | null>(null);

  const removeNotification = () => setNotification(null);

  const { children } = props;

  const addNotification = (message: string, status: string, type: NotificationType) =>
    setNotification({ message, status, type });

  const contextValue: NotificationContextProps = {
    notification: notification,
    addNotification: useCallback(
      (message, status, type = NotificationType.Toast) => addNotification(message, status, type),
      []
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return <NotifcationContext.Provider value={contextValue}>{children}</NotifcationContext.Provider>;
}
