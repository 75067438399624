import React from "react";
import { useGetTerminDaten } from "domain/SessionHandling";
import { Route, RouteProps, useHistory } from "react-router-dom";

import { useNotification, NotificationType } from "../services/Notification";

const ProtectedRoute: React.FC<RouteProps> = (routeProps) => {
  // hooks
  const { addNotification } = useNotification();
  const history = useHistory();
  const termindaten = useGetTerminDaten();
  const isLoggedIn = termindaten?.token;

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  if (isLoggedIn) {
    return <Route {...routeProps} />;
  } else {
    addNotification("Keine gültigen Anmeldedaten gefunden", "Nicht angemeldet", NotificationType.Alert);
    history.push("/");
    return null;
  }
};

export default ProtectedRoute;
