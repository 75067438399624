import { useContext } from "react";
import { AxiosError } from "axios";

import { UseMutationResult, useMutation } from "react-query";

import { useNotification, NotificationType } from "../services/Notification";
import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { ApiContext } from "services/ApiContext";
import { NeuerTermin } from "./TerminsucheTypes";

export const useCreateTermin = (): UseMutationResult<unknown, AxiosError, NeuerTermin> => {
  const { addNotification } = useNotification();
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);

  const CreateTermin = async (terminChange: NeuerTermin): Promise<unknown> => {
    log.debug({ obj: terminChange }, `trying to create new Termin'`);

    const response = await api.post("api/Termin", terminChange);
    log.trace({ obj: response.data }, `created Termin'`);
    return response.data;
  };

  return useMutation<unknown, AxiosError, NeuerTermin>(CreateTermin, {
    onSuccess: () => {
      log.debug("successfully created new Termin");
    },
    onError: (err) => {
      if (err.response?.status === 401)
        addNotification(
          "Ihre Anmeldung ist ungültig oder abgelaufen, bitte melden Sie sich neu an.",
          "Anmeldedaten ungültig",
          NotificationType.Alert
        );
      else logAxiosError("Error creating new Termin", "Fehler beim Speichern des Termins", err);
    },
  });
};
