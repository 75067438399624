export interface AuthData {
  kundenDaten: CustomerData;
  auftragsnummer: string;
  zaehlernummer: string;
  token: string;
  tokenExpirationTime: number;
  branding?: Branding;
}

export interface CustomerData {
  anrede: string;
  enthaeltBemerkung: boolean;
  kundeAdresse: string;
  kundeKontaktDaten: KundeKontaktDaten;
  sparte: string;
  termin: Termin;
  terminKurzBevorstehend: boolean;
}

export interface KundeKontaktDaten {
  bemerkung: string;
  kundeEmail: string;
  kundeTelefon: string;
  wuenschtBestaetigung: boolean;
  wuenschtErinnerung: boolean;
}

export interface Termin {
  dateFrom: Date;
  dateTo: Date;
}

export interface AuftragsLogin {
  auftragsnummer: string;
  zaehlernummer: string;
}

export const emptyAuftragsLogin: AuftragsLogin = {
  auftragsnummer: "",
  zaehlernummer: "",
};

export interface TerminSlotData {
  id: string;
  timeSlot: string;
  from: Date;
  to: Date;
}

export interface SelectedSlot {
  id: string;
  von: Date;
  bis: Date;
}

export interface NeuerTermin {
  erinnerung: boolean;
  bestaetigung: boolean;
  neuerTerminVon: Date;
  neuerTerminBis: Date;
  alterTerminVon: Date;
  nachricht: string;
  email: string;
  phone: string;
}

export interface Branding {
  datenschutzLink: string;
  telefon: string;
  impressumLink: string;
  informationspflichtenLink: string;
  logo: string;
}
