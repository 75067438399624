import { ActionButton } from "@gelsenwasser/react";
import { Formik, Form as FormikForm, FormikHelpers, FormikProps } from "formik";
import { FormikControl } from "3rdParty/formik-react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { log } from "common/Logger";
import * as yup from "yup";
import { AuftragsLogin, CustomerData, emptyAuftragsLogin } from "../TerminsucheTypes";
import terminportal_logo from "assets/terminportal_logo.png";
import { ApiContext } from "services/ApiContext";
import { useErrorLogger } from "common/LoggerHooks";
import { parse } from "qs";

import styles from "./Anmeldung.module.scss";
import { SchemaOf } from "yup";
import Loader from "components/Loader";

const AnmeldungSchema: SchemaOf<AuftragsLogin> = yup
  .object({
    auftragsnummer: yup.string().trim().required("Bitte eine Auftragsnummer eingeben"),
    zaehlernummer: yup.string().trim().required("Bitte eine Zählernummer eingeben"),
  })
  .defined();

const Anmeldung: React.FC = () => {
  // hooks
  const history = useHistory();
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const formRef = useRef<FormikProps<AuftragsLogin>>(null);

  // states
  const [isLoading, setIsLoading] = useState(false);
  // parameter
  const location = useLocation();

  // queries und mutationen
  // effekte
  useEffect(() => {
    sessionStorage.clear();
    document.title = "TerminPortal - Anmeldung";
  }, []);

  useEffect(() => {
    if (!location.search || formRef.current === null) {
      log.debug("no search location or no form reference found");
      return;
    }
    log.debug("parsing search location: %s", location.search);

    const parsed = parse(location.search, { ignoreQueryPrefix: true });

    const formikCtx = formRef.current;
    if (parsed.orderId) formikCtx.setFieldValue("auftragsnummer", parsed.orderId);
    if (parsed.meterId) formikCtx.setFieldValue("zaehlernummer", parsed.meterId);

    if (parsed.orderId || parsed.meterId) {
      formikCtx.validateForm().then(() => formikCtx.submitForm());
    }
  }, [location.search, formRef]);

  // daten
  // handler
  const doLogin = async (auftragsLogin: AuftragsLogin) => {
    setIsLoading(true);
    const response = await api.post<CustomerData>("/api/Login/", auftragsLogin);
    log.trace(
      { obj: response.data },
      `got AuthData response for Auftragsnummer '${auftragsLogin.auftragsnummer}' and Zaehlernummer '${auftragsLogin.zaehlernummer}'`
    );
    sessionStorage.setItem("Termindaten", JSON.stringify(response.data));
  };

  const onSubmit = (values: AuftragsLogin, { setSubmitting }: FormikHelpers<AuftragsLogin>) => {
    log.debug("logging user in");

    doLogin(AnmeldungSchema.cast(values) as AuftragsLogin)
      .then(() => {
        setIsLoading(false);
        history.push("/daten");
      })
      .catch((error) => {
        setIsLoading(false);
        setSubmitting(false);
        logAxiosError("error during login", "Fehler bei der Anmeldung", error);
      });
  };

  log.debug("zeigen der anwendung");

  return (
    <div className={styles.home}>
      <Formik
        initialValues={emptyAuftragsLogin}
        validationSchema={AnmeldungSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
      >
        {({ handleSubmit, isSubmitting }): JSX.Element => {
          return (
            <FormikForm onSubmit={handleSubmit}>
              <Card style={{ width: "400px" }}>
                <Card.Body>
                  <Card.Title>
                    <img src={terminportal_logo} width="316px" height="50px" />
                  </Card.Title>
                  <p>Hier können Sie Ihren Termin zum Zählerwechsel ändern.</p>
                  <FormikControl name="auftragsnummer" label="Auftragsnummer" autoComplete="off" />
                  <FormikControl name="zaehlernummer" label="Zählernummer" autoComplete="off" />
                  <ActionButton caption="Anmelden" type="submit" disabled={isSubmitting} />
                </Card.Body>
              </Card>
            </FormikForm>
          );
        }}
      </Formik>
      <Loader show={isLoading} caption="Daten werden gesendet ..." />
    </div>
  );
};
export default Anmeldung;
