import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { GetTerminSteps } from "App";
import ProcessLine from "components/ProcessLine";
import { ContentBox, FormSection, ActionButton } from "@gelsenwasser/react";

import { ReactComponent as BestaetigungSvg } from "../assets/bestaetigung.svg";
import { contentBoxStyles } from "components/Layout";

const Bestaetigung: React.FC = () => {
  // hooks
  const history = useHistory();
  const steps = GetTerminSteps();

  // states
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    document.title = `TerminPortal - ${steps[4].title}`;
  }, []);

  // daten
  // handler
  const onRestart = () => {
    sessionStorage.clear();
    history.push("/");
  };

  return (
    <>
      <ProcessLine steps={steps} currentStep={"Bestaetigung"} isCompleted />
      <ContentBox title="Bestätigung" {...contentBoxStyles}>
        <Row>
          <Col>
            <BestaetigungSvg height={220} className="mt-4" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Vielen Dank.</h2>
            <h3>Wir haben Ihre Informationen erhalten.</h3>
          </Col>
        </Row>
        <FormSection title="">
          <Row>
            <Col>
              <ActionButton className="float-right" caption="Abmelden" onClick={onRestart} />
            </Col>
          </Row>
        </FormSection>
      </ContentBox>
    </>
  );
};

export default Bestaetigung;
