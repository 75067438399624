import React from "react";
import { Modal } from "react-bootstrap";

import Zahnraeder from "assets/zahnraeder.svg";

interface Props {
  show: boolean | undefined;
  caption: string;
}

const Loader: React.FC<Props> = ({ show, caption }: Props) => {
  return (
    <Modal show={show} centered backdrop="static">
      <Modal.Body className="d-flex align-items-center">
        {/* <Spinner animation="border" role="status" variant="green" /> */}
        <object type="image/svg+xml" height={48} width={48} data={Zahnraeder}>
          svg-animation
        </object>
        <span className="ml-2">{caption}</span>
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
