import React from "react";
import { Route, Switch } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import "./App.scss";

// import { NotificationProvider, Notification } from "@gelsenwasser/react";
import { NotificationProvider, Notification } from "./services/Notification";

// static components
import { Layout } from "./components/Layout";

import NoMatch from "./domain/NoMatch";
import { ApiContextProvider } from "./services/ApiContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { ProcessLineStep } from "components/ProcessLine";

import Anmeldung from "domain/Anmeldung";
import Daten from "domain/Daten";
import TerminAuswahl from "domain/TerminAuswahl";
import Benachrichtigung from "domain/Benachrichtigung";
import Absenden from "domain/Absenden";
import Bestaetigung from "domain/Bestaetigung";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const GetTerminSteps = (): Array<ProcessLineStep> => {
  return [
    { title: "Daten", key: "Daten", url: `/daten` },
    { title: "Terminauswahl", key: "Terminauswahl", url: `/terminauswahl` },
    { title: "Benachrichtigungen", key: "Benachrichtigungen", url: `/benachrichtigung` },
    { title: "Absenden", key: "Absenden", url: `/absenden` },
    { title: "Bestätigung", key: "Bestaetigung", url: `/bestaetigung` },
  ];
};
export default class App extends React.Component {
  static displayName = App.name;

  render(): JSX.Element {
    return (
      <>
        <ApiContextProvider>
          <NotificationProvider>
            <Notification />
            <QueryClientProvider client={queryClient}>
              <Switch>
                <Route exact path="/" component={Anmeldung} />
                <Layout>
                  <ProtectedRoute exact path="/daten" component={Daten} />
                  <ProtectedRoute exact path="/terminauswahl" component={TerminAuswahl} />
                  <ProtectedRoute exact path="/benachrichtigung" component={Benachrichtigung} />
                  <ProtectedRoute exact path="/absenden" component={Absenden} />
                  <ProtectedRoute exact path="/bestaetigung" component={Bestaetigung} />
                </Layout>
                {/* Unbekannte Seite */}
                <Route path="*" component={NoMatch} />
              </Switch>

              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </NotificationProvider>
        </ApiContextProvider>
      </>
    );
  }
}
